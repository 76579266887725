import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/section";
import MainHeading from "../components/main-heading";
import ImageText from "../components/image-text";
import TextImage from "../components/text-image";
import Li from "../components/li";
import UiLink from "../components/ui-link";
import UiHeading from "../components/ui-heading";
import UiLinkContainer from "../components/ui-link-container";

import mainImg from "../images/undraw_agreement_aajr.svg";

import agreementIcon from "../images/icons/agreement.svg";
import tieIcon from "../images/icons/tie.svg";


function PravidlaPage() {
    return (
      <Layout>
          <SEO
        title="Často kladené dotazy"
      />
        <Section>
            <MainHeading heading="Pravidla skupiny PC poradna" section="Rozcestník"/>
            <ImageText image={mainImg}>
            <ol className="text-lg sm:text-xl ml-8">
              <Li>
                <p>PC poradna není jen o PC!</p>
              </Li>
              <Li>
                <p>Pište tak, abychom vám rozuměli.</p>
              </Li>
              <Li>
                <p>Jako autor dotazu komunikujte s ostatními. Veřejně.</p>
              </Li>
              <Li>
                <p>Odpovídejte tazatelům tak, abyste jim pomohli. Veřejně.</p>
              </Li>
              <Li>
                <p>Chovejte se k ostatním členům zdvořile.</p>
              </Li>
              <Li>
                <p>Respektujte moderátory skupiny.</p>
              </Li>
            </ol>
            </ImageText>
            <UiLinkContainer>
                  <UiLink 
                  headerText="Úplná pravidla skupiny"
                  link="https://www.notion.so/pcporadna/Pravidla-skupiny-PC-poradna-1c54be41ed1e47c6af3d4d72e3fc03e8"
                  image={agreementIcon}
                  />
                  <span className="flex-grow ml-16 mr-4 border-b border-gray-200"></span>
                  <UiLink 
                  headerText="Co je to netiketa"
                  link="https://medium.com/edtech-kisk/pravidla-netikety-ea92f7c3e58b"
                  image={tieIcon}
                  />
              </UiLinkContainer>
        </Section>

      </Layout>

      );
    }
    
export default PravidlaPage;
